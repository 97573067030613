<!-- 
	This is the DataTables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>

	<div>

        <div v-if="!loadingPage">
        
            <a-card :bordered="false" class="header-solid mb-24 px-25" :bodyStyle="{padding: 0, paddingTop: '16px'}">
                <a-row class="mx-25 pt-10">
                    <a-col :span="12">
                        <h5 class="font-semibold">Student Profile</h5>
                    </a-col>
                    <a-col :span="12" class="text-right">
                        <a-button type="primary" href="/student-management/students" class="px-20 mb-10">
                            <a-icon type="arrow-left" theme="outlined" class="text-white mr-5" />Back 
                        </a-button>
                    </a-col>

                    <a-col :span="24">
                        <a-divider class="mt-0 pt-0"/>
                    </a-col>
                </a-row>

                <a-row type="flex" align="middle" class="mx-25 pb-25">
                    <a-col :span="10" :sm="6" :md="3" :lg="2">
                        <a-avatar :size="74" shape="square" src="images/face-1.jpg" />
                    </a-col>

                    <a-col :span="14" :sm="10" :md="11" :lg="12">
                        <h5 class="font-semibold m-0">
                            {{ student.firstName }} {{ student.middleName != null ? `${student.middleName[0].toUpperCase()}.` : '' }} {{ student.lastName }}
                        </h5>
                        <p class="pt-2 mb-0 pb-0">{{ student.dateOfBirth != null ? `${getAge(student.dateOfBirth)} years old` : '-' }}</p>
                        <!-- <p class="mt-0 pt-0">{{ 'Standard 7' }}</p> -->
                    </a-col>

                    <a-col :span="24" :sm="8" :md="10" :lg="10" class="text-right">
                        <small class="mr-5">Status</small>
                        <a-switch default-checked class="bg-success" v-model="student.isActive" />
                    </a-col>

                </a-row>

            </a-card>

            
            <div class="mt-15 pt-10">
                    <a-row type="flex" :gutter="24">
                        <a-col :span="24">
                            <a-tabs class="tabs-sliding primary" v-model="activeTab" @change="onTabChange">

                                <a-tab-pane :key="1" tab="Basic Details">
                                    
                                    <a-card class="header-solid px-25 mb-25">
                                        <div class="mx-25 pb-0 mb-0" v-if="!student.loading">
                                            <a-row>
                                                <a-col :span="12" class="pt-15">
                                                    <h5 class="font-semibold mb-0 pb-0 mt-10">Basic Details</h5>
                                                </a-col>

                                                <a-col :span="12" class="pt-15 text-right">
                                                    <a-button v-if="student.disabled" id="txtBtn" type="text" class="pb-0 pt-0 mb-10" size="large" style="color: #FB8C00;" @click="onEditBasicDetails">
                                                        <a-icon type="form" /> Edit
                                                    </a-button>
                                                </a-col>

                                                <a-col :span="24">
                                                    <a-divider class="mt-0 pt-0"/>
                                                </a-col>

                                            </a-row>
                                            <a-row :gutter="24">
                                                <a-col :span="24" :md="24">
                                                    <a-form-model
                                                        :model="student"
                                                        :form="form"
                                                        class="login-form mb-0 pb-0"
                                                        layout="vertical"
                                                        @submit.prevent="handleSubmit">

                                                        <a-row :gutter="24">
                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="First Name *"
                                                                    :colon="false">
                                                                        <a-input 
                                                                            v-decorator="['firstname', { rules: [{ required: true, message: 'Please input your note!' }] }]"
                                                                            v-model="student.firstName" :disabled="student.disabled"/>
                                                                </a-form-item>
                                                            </a-col>

                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="Middle Name"
                                                                    :colon="false">
                                                                        <a-input 
                                                                            v-decorator="['middleName', { rules: [{ required: true, message: 'Please input your note!' }] }]"
                                                                            v-model="student.middleName" :disabled="student.disabled"/>
                                                                </a-form-item>
                                                            </a-col>

                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="Last Name *"
                                                                    :colon="false">
                                                                        <a-input 
                                                                            v-decorator="['lastName', { rules: [{ required: true, message: 'Please input your note!' }] }]"
                                                                            v-model="student.lastName" :disabled="student.disabled"/>
                                                                </a-form-item>
                                                            </a-col>

                                                        </a-row>


                                                        <a-row :gutter="24">
                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="Gender *"
                                                                    placeholder="Select Gender"
                                                                    :colon="false">
                                                                    <a-select v-model="student.gender" placeholder="Select Gender" :disabled="student.disabled">
                                                                        <a-select-option v-for="option in genders"
                                                                            :value="option.uuid"
                                                                            :key="option.name">
                                                                            {{ option.name }}
                                                                        </a-select-option>
                                                                    </a-select>
                                                                </a-form-item>
                                                            </a-col>

                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="Date of Birth"
                                                                    :colon="false">
                                                                        <a-date-picker
                                                                            v-model="student.dateOfBirth2" 
                                                                            format="DD MMM YYYY"
                                                                            style="width: 100%"
                                                                            placeholder="" :disabled="student.disabled"/>
                                                                </a-form-item>
                                                            </a-col>

                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="Religion"
                                                                    :colon="false">
                                                                    <a-select v-model="student.religion" placeholder="Select Religion" :disabled="student.disabled">
                                                                        <a-select-option v-for="option in religions"
                                                                            :value="option"
                                                                            :key="option">
                                                                            {{ option }}
                                                                        </a-select-option>
                                                                    </a-select>
                                                                </a-form-item>
                                                            </a-col>

                                                        </a-row>


                                                        <a-row :gutter="24">

                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="Date of Joining"
                                                                    :colon="false">
                                                                        <a-date-picker
                                                                            v-model="student.dateOfJoining2" 
                                                                            :disabled="student.disabled"
                                                                            format="DD MMM YYYY"
                                                                            style="width: 100%"
                                                                            placeholder="" />
                                                                </a-form-item>
                                                            </a-col>

                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="Nationality"
                                                                    :colon="false">
                                                                        <a-select 
                                                                            show-search
                                                                            :disabled="student.disabled"
                                                                            v-model="student.nationality" 
                                                                            placeholder="Select Country"
                                                                            :options="countries.map(option => ({ label: option.label, value: option.label }))" />
                                                                </a-form-item>
                                                            </a-col>

                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="Picture of Student (Passport Size)"
                                                                    :colon="false">
                                                                        <a-input 
                                                                            v-decorator="['studentPicture', { rules: [{ required: true, message: 'Please input your note!' }] }]"
                                                                            v-model="student.studentPicture" :disabled="student.disabled"/>
                                                                </a-form-item>
                                                            </a-col>

                                                        </a-row>

                                                        <a-row :gutter="24">

                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="Address"
                                                                    :colon="false">
                                                                        <a-textarea :rows="4" placeholder="" v-model="student.address" :disabled="student.disabled"/>
                                                                </a-form-item>
                                                            </a-col>

                                                        </a-row>

                                                        <a-row :gutter="24">

                                                            <a-col :span="24" :lg="24" :md="24" :sm="24" class="mt-10">
                                                                <a-form-item class="mt-20"> 
                                                                    <a-button v-if="!student.disabled" type="secondary" @click="getStudentDetails" class="mr-25">
                                                                        Cancel
                                                                    </a-button>
                                                                    <a-button v-if="!student.disabled" type="primary" :loading="student.loading" html-type="submit" class="ml-25">
                                                                        {{ student.isCreate ? 'Save' : 'Update' }}
                                                                    </a-button>
                                                                </a-form-item>
                                                            </a-col>

                                                        </a-row>

                                                    </a-form-model>
                                                </a-col>
                                            </a-row>
                                        </div>

                                        <div v-if="student.loading">
                                            <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 80vh">
                                                <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">
                                                    <a-spin size="large" />
                                                </a-col>
                                            </a-row>
                                        </div>
                                    </a-card>

                                </a-tab-pane>

                                <a-tab-pane :key="2" tab="Guardian Information">

                                    <a-card class="header-solid px-25 mb-25">
                                        <div class="mx-25 pb-0 mb-0">
                                            <a-row>
                                                <a-col :span="12" class="pt-15">
                                                    <h5 class="font-semibold mb-0 pb-0 mt-10">List of Guardians</h5>
                                                </a-col>

                                                <a-col :span="12" class="pt-15 text-right">
                                                    <a-button type="primary" @click="onCreateGuardian" class="px-20 mb-10">
                                                        <a-icon type="plus" theme="outlined" class="text-white mr-5" />Add Guardian 
                                                    </a-button>
                                                </a-col>

                                                <a-col :span="24">
                                                    <a-divider class="mt-0 pt-0"/>
                                                </a-col>

                                            </a-row>
                                            <a-row :gutter="24">
                                                <a-col :span="24" :md="24">
                                                    <a-table class="mt-20"  
                                                        size="middle"
                                                        :bordered="false"
                                                        :columns="guardianColumns" 
                                                        :data-source="guardians" 
                                                        :rowKey="record => record.uuid"
                                                        :loading="guardianColumns.loading" 
                                                        :pagination="{pageSize: 10}">

                                                            <template slot="s_no" slot-scope="s_no, record, index">
                                                                {{ index + 1 }}
                                                            </template>

                                                            <template slot="fullname" slot-scope="fullname, record">
                                                                {{ record.firstName }} {{ record.middleName != null ? `${record.middleName[0].toUpperCase()}.` : '' }} {{ record.lastName }}
                                                            </template>

                                                            <template slot="relationship" slot-scope="relationship">
                                                                {{ relationship != null ? relationship.name : '-' }}
                                                            </template>

                                                            <template slot="contacts" slot-scope="contacts, record">
                                                                Phone: {{ record.phone }} <br/>
                                                                Email: {{ record.email }}
                                                            </template>
                                                            
                                                            <template slot="createdAt" slot-scope="createdAt">
                                                                {{ $Moment(createdAt).format("DD MMM YYYY") }}
                                                            </template>

                                                            <template slot="action" slot-scope="action, record">
                                                                <a-button id="txtBtn" type="text" class="pb-0 pt-0" style="color: #FB8C00;" @click="onEditGuardian(record)">
                                                                    <a-icon type="form" /> Edit
                                                                </a-button>
                                                                &nbsp;&nbsp;
                                                                <a-button id="txtBtn" type="text" class="pb-0 pt-0" style="color: #F44335;" @click="showGuardianDeletionConfirmation(record.uuid)">
                                                                    <a-icon type="delete" theme="outlined"/> Delete
                                                                </a-button>
                                                            </template>
                                                        
                                                    </a-table>
                                                </a-col>
                                            </a-row>
                                        </div>
                                    </a-card>

                                </a-tab-pane>
                                
                                <!-- <a-tab-pane :key="3" tab="Academic Details">

                                    <a-card class="header-solid" :bodyStyle="{padding: '50px', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center'}">
                                        <a href="#" class="text-center text-muted font-bold">
                                            <h6 class="font-semibold text-muted">Empty Tab</h6>
                                        </a>
                                    </a-card>

                                </a-tab-pane>
                                
                                <a-tab-pane :key="4" tab="Backup">

                                    <a-card class="header-solid" :bodyStyle="{padding: '50px', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center'}">
                                        <a href="#" class="text-center text-muted font-bold">
                                            <h6 class="font-semibold text-muted">Empty Tab</h6>
                                        </a>
                                    </a-card>

                                </a-tab-pane>                -->

                            </a-tabs>

                        </a-col>
                    </a-row>
            </div>

        </div>

        <div v-if="loadingPage">
            <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 80vh">
                <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">
                    <a-spin size="large" />
                </a-col>
            </a-row>
        </div>


        <!-- GUARDIAN FORM MODAL -->
		<a-modal v-model="guardian.showModal" :footer="null" width="736px">
			<h4 class="text-center">{{ guardian.isCreate ? 'Add Guardian' : 'Edit Guardian'}}</h4>
			<a-form-model
				:model="guardian"
                :form="form"
                class="login-form mb-0 pb-0"
                layout="vertical"
				@submit.prevent="handleSubmitGuardian">

                <a-row :gutter="24">
                    <a-col :span="24" :lg="12" :md="12" :sm="12">
                        <a-form-item 
                            class="mt-20 mb-10 pb-0" 
                            label="First Name *"
                            :colon="false">
                                <a-input 
                                    v-decorator="['firstName', { rules: [{ required: true, message: 'Please input your note!' }] }]"
                                    v-model="guardian.firstName"/>
                        </a-form-item>
                    </a-col>
                
                    <a-col :span="24" :lg="12" :md="12" :sm="12">
                        <a-form-item 
                            class="mt-20 mb-10 pb-0" 
                            label="Middle Name"
                            :colon="false">
                                <a-input 
                                    v-decorator="['middleName', { rules: [{ required: true, message: 'Please input your note!' }] }]"
                                    v-model="guardian.middleName"/>
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row :gutter="24">

                    <a-col :span="24" :lg="12" :md="12" :sm="12">
                        <a-form-item 
                            class="mt-20 mb-10 pb-0" 
                            label="Last Name *"
                            :colon="false">
                                <a-input 
                                    v-decorator="['lastName', { rules: [{ required: true, message: 'Please input your note!' }] }]"
                                    v-model="guardian.lastName"/>
                        </a-form-item>
                    </a-col>

                    <a-col :span="24" :lg="12" :md="12" :sm="12">
                        <a-form-item 
                            class="mt-20 mb-10 pb-0" 
                            label="Gender *"
                            :colon="false">
                                <a-select v-model="guardian.gender" placeholder="Select Gender">
                                    <a-select-option v-for="option in genders"
                                        :value="option.uuid"
                                        :key="option.name">
                                        {{ option.name }}
                                    </a-select-option>
                                </a-select>
                        </a-form-item>
                    </a-col>

                </a-row>

                

                <a-row :gutter="24">

                    <a-col :span="24" :lg="12" :md="12" :sm="12">
                        <a-form-item 
                            class="mt-20 mb-10 pb-0" 
                            label="Relationship *"
                            :colon="false">
                                <a-select 
									show-search
                                    v-model="guardian.relationshipUuid" 
                                    placeholder="Select Relationship"
                                    :options="relationships.map(option => ({ label: option.name, value: option.uuid }))" />
                        </a-form-item>
                    </a-col>

                    <a-col :span="24" :lg="12" :md="12" :sm="12">
                        <a-form-item 
                            class="mt-20 mb-10 pb-0" 
                            label="Email Address*"
                            :colon="false">
                                <a-input 
                                    v-decorator="['email', { rules: [{ required: true, message: 'Please input your note!' }] }]"
                                    v-model="guardian.email"/>
                        </a-form-item>
                    </a-col>
                </a-row>
                

                <a-row :gutter="24">

                    <a-col :span="24" :lg="12" :md="12" :sm="12">
                        <a-form-item 
                            class="mt-20 mb-10 pb-0" 
                            label="Phone Number *"
                            :colon="false">
                                <a-input 
                                    v-decorator="['phone', { rules: [{ required: true, message: 'Please input your note!' }] }]"
                                    v-model="guardian.phone"/>
                        </a-form-item>
                    </a-col>

                    <a-col :span="24" :lg="12" :md="12" :sm="12">
                        <a-form-item 
                            class="mt-20 mb-10 pb-0" 
                            label="Occupation"
                            :colon="false">
                                <a-input 
                                    v-decorator="['occupation', { rules: [{ required: true, message: 'Please input your note!' }] }]"
                                    v-model="guardian.occupation"/>
                        </a-form-item>
                    </a-col>
                </a-row>


                <a-row :gutter="24">

                    <a-col :span="24" :lg="12" :md="12" :sm="12">
                        <a-form-item 
                            class="mt-20 mb-10 pb-0" 
                            label="Home Address"
                            :colon="false">
                                <a-textarea :rows="4" placeholder="" v-model="guardian.homeAddress"/>
                        </a-form-item>
                    </a-col>

                </a-row>

                <a-row :gutter="24">
                    <a-col :span="24" :lg="24" :md="24" :sm="24">
                        <a-form-item class="mt-20"> 
                            <a-button type="primary" :loading="guardian.loading" html-type="submit" class="">
                                {{ guardian.isCreate ? 'Create' : 'Update' }}
                            </a-button>
                        </a-form-item>
                    </a-col>
                </a-row>

			</a-form-model>
			
		</a-modal>

        <!-- GUARDIAN DELETE CONFIRMATION -->
		<a-modal v-model="deleteDataGuardian.showModal" :footer="null" width="416px">

			<a-row :gutters="24" class="">
				<a-col :span="24" :md="3" :lg="3" :xl="3" class="mt-10">
					<a-icon type="warning" theme="outlined" class="text-warning" style="font-size: 25px;"/>
				</a-col>

				<a-col :span="24" :md="21" :lg="21" :xl="21">

					<a-row :gutters="24">
						<a-col :span="24">
							<h6>Do you want to delete this data?</h6>
							<p>Once you delete, you will not be able to recover this inforamtion.</p>
						</a-col>
					</a-row>

					<a-row :gutters="24">
						<a-col :span="24" class="text-right">
							<a-button id="txtBtn" type="text" class="text-secondary" @click="cancelGuardianDeletion">
								Cancel
							</a-button>
							&nbsp;&nbsp;
							<a-button id="txtBtn" type="text" class="text-danger" @click="onDeleteGuardian">
								 Delete
							</a-button>
						</a-col>
					</a-row>
				</a-col>
			</a-row>
      
		</a-modal>

	</div>

</template>

<script>

    import { Countries } from '../Resources/Countries'

    const countries = Countries;

	// Second table's list of columns.
	const guardianColumns = [
		{
            title: 'S/N',
            dataIndex: 's_no',
			scopedSlots: { customRender: 's_no' },
        },
		{
			title: 'FULL NAME',
			dataIndex: 'fullname',
			// sorter: (a, b) => a.firstName.length - b.firstName.length,
			// sortDirections: ['descend', 'ascend'],
			scopedSlots: { customRender: 'fullname' },
		},
        {
			title: 'RELATIONSHIP',
			dataIndex: 'relationship',
			scopedSlots: { customRender: 'relationship' },
		},
		{
			title: 'GENDER',
			dataIndex: 'gender',
			scopedSlots: { customRender: 'gender' },
		},
		{
			title: 'CONTACTS',
			dataIndex: 'contacts',
			scopedSlots: { customRender: 'contacts' },
		},
		{
			title: 'OCCUPATION',
			dataIndex: 'occupation',
			scopedSlots: { customRender: 'occupation' },
		},
		{
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			scopedSlots: { customRender: 'createdAt' },
		},
		{
			title: 'ACTION',
			dataIndex: 'action',
			scopedSlots: { customRender: 'action' },
			align: "center"
		},
	];

	
	export default {
		components: {
		},
		data() {
			return {
                activeTab: 1,
				academicPagination: {
					search: '',
					loading: false,
                    perPage: 10,
                    currentPage: 1,
                    perPageOptions: [
						{label: "10", value: 10}, 
						{label: "20", value: 20}, 
						{label: "30", value: 30}, 
						{label: "50", value: 50}
					],
                    total: 10
                },
                gurdianPagination: {
					search: '',
					loading: false,
                    perPage: 10,
                    currentPage: 1,
                    perPageOptions: [
						{label: "10", value: 10}, 
						{label: "20", value: 20}, 
						{label: "30", value: 30}, 
						{label: "50", value: 50}
					],
                    total: 10
                },
                form: this.$form.createForm(this, { name: 'newItemForm' }),

				genders: [
					{ uuid: "Male", name: "Male" },
					{ uuid: "Female", name: "Female" },
				],
                relationships: [],
				countries,
				religions: ["Christian", "Muslim", 'Buddhist', "Hindu", "Others", "Non-Religious"],

				guardianColumns,
				message: '',

                loadingPage: false,

				student: {
					uuid: null,
					firstName: null,
					middleName: null,
					lastName: null,
					gender: null,
					address: null,
					dateOfBirth: null,
					dateOfBirth2: null,
					religion: null,
					nationality: null,
					dateOfJoining: null,
					dateOfJoining2: null,
                    pictureUrl: null,
					studentPicture: null,
                    isActive: false, 
					isCreate: false,
                    disabled: true,
					loading: false,
					showModal: false,
				},

                academics: [],
                academic: {
					uuid: null,
                    studentUuid: this.$route.params.uuid,
                    classroomUuid: null,
                    admissionNumber: null,
                    admissionDate: null,
                    isActive: false,
                },

                guardians: [],

                guardian: {
					uuid: null,
                    studentUuid: this.$route.params.uuid,
                    relationshipUuid: null,
					firstName: null,
					middleName: null,
					lastName: null,
					gender: null,
					email: null,
					phone: null,
					occupation: null,
					homeAddress: null,
					isCreate: false,
					loading: false,
					showModal: false,
                },

                deleteDataGuardian: {
					uuid: null,
					showModal: false,
				},
			
			}
		},
		created() {
			this.getAllDetails();
            this.getRelationships();
            this.getActiveTab()
		},
		methods: {

            async onTabChange(event) {
                await localStorage.setItem("studentActiveTab", event.toString())
            },

            async getActiveTab() {
                let activeTab = await localStorage.getItem("studentActiveTab")

                if(activeTab) {
                    this.activeTab = parseInt(activeTab);
                }
            },

            async getAllDetails() {
                await this.getStudentDetails();
                await this.getGuardians();
            },

			getStudentDetails() {
                this.student.loading = true;

                let url = `${this.$BACKEND_URL}/students/${this.$route.params.uuid}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        this.student.disabled = true;
                        this.student.firstName = response.data.firstName;
                        this.student.middleName = response.data.middleName;
                        this.student.lastName = response.data.lastName;
                        this.student.gender = response.data.gender;
                        this.student.address = response.data.address;
                        this.student.dateOfBirth = response.data.dateOfBirth;
                        this.student.dateOfBirth2 = response.data.dateOfBirth;
                        this.student.religion = response.data.religion;
                        this.student.nationality = response.data.nationality;
                        this.student.dateOfJoining = response.data.dateOfJoining;
                        this.student.dateOfJoining2 = response.data.dateOfJoining;
                        this.student.pictureUrl = response.data.pictureUrl;
                        this.student.isActive = response.data.isActive;
                    }
                    this.student.loading = false;
                    this.loadingPage = false;
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "danger"

                    this.notify(type, message)

                    this.student.loading = false;
                });
            },

            getRelationships() {
                const currentPage = 1;
				const perPage = 100;

                let url = `${this.$BACKEND_URL}/relationships?page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        this.relationships = response.data.data;
                    }
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "danger"

                    this.notify(type, message)

                });
            },

			getAge (birthDate) {
				var years = new Date(new Date() - new Date(birthDate)).getFullYear() - 1970;

				return years
			},

            onEditBasicDetails() {
                this.student.disabled = false;
            },

            async handleSubmit() {

                this.student.loading = true;
    
                let url = `${this.$BACKEND_URL}/students/${this.$route.params.uuid}`

                this.student.dateOfBirth = this.student.dateOfBirth2 != null && this.student.dateOfBirth2.$d != null ? this.$Moment(this.student.dateOfBirth2.$d).format("YYYY-MM-DD") : this.student.dateOfBirth2; 
                this.student.dateOfJoining = this.student.dateOfJoining2 != null && this.student.dateOfJoining2.$d != null ? this.$Moment(this.student.dateOfJoining2.$d).format("YYYY-MM-DD") : this.student.dateOfJoining2; 

                this.$AXIOS.patch(url, this.student).then(response => {

                    if (response.status >= 200 && response.status < 210) {

                        this.form.resetFields()

                        this.student.loading = false;
                        this.student.disabled = true;

                        let message = response.data.message
                        let type = "success"

                        this.notify(type, message)

                        this.getStudentDetails();

                    }
    
                }).catch(error => {
                
                    this.student.loading = false;
        
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "danger"

                    this.notify(type, message)
                });
				
			},


            //GUARDIANS
            getGuardians() {
                this.gurdianPagination.loading = true;

                const currentPage = 1;
				const perPage = 100;

                let url = `${this.$BACKEND_URL}/students/guardian/${this.$route.params.uuid}/?page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        this.guardians = response.data.data;
                    }
                    this.gurdianPagination.loading = false;
                    
                }).catch((error) => {
                    this.gurdianPagination.loading = false;

                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "danger"

                    this.notify(type, message)

                });
            },

            onCreateGuardian() {
                this.guardian.uuid = null;
                this.guardian.studentUuid = this.$route.params.uuid;
                this.guardian.relationshipUuid = null;
                this.guardian.firstName = null;
                this.guardian.middleName = null;
                this.guardian.lastName = null;
                this.guardian.gender = null;
                this.guardian.email = null;
                this.guardian.phone = null;
                this.guardian.occupation = null;
                this.guardian.homeAddress = null;
				this.guardian.isCreate = true;
				this.guardian.loading = false;
				this.guardian.showModal = true;
            },

			onEditGuardian(item) {
                this.guardian.uuid = item.uuid;
                this.guardian.studentUuid = this.$route.params.uuid;
                this.guardian.relationshipUuid = item.relationship.uuid;
                this.guardian.firstName = item.firstName;
                this.guardian.middleName = item.middleName;
                this.guardian.lastName = item.lastName;
                this.guardian.gender = item.gender;
                this.guardian.email = item.email;
                this.guardian.phone = item.phone;
                this.guardian.occupation = item.occupation;
                this.guardian.homeAddress = item.homeAddress;
				this.guardian.isCreate = false;
				this.guardian.loading = false;
				this.guardian.showModal = true;
            },


            async handleSubmitGuardian() {

				if(this.guardian.isCreate) {

					this.guardian.loading = true;
		
					let url = `${this.$BACKEND_URL}/students/guardian`
					
					this.guardian.uuid = undefined;

					this.$AXIOS.post(url, this.guardian).then(response => {

						if (response.status >= 200 && response.status < 210) {

							this.form.resetFields()

							this.guardian.uuid = null;
                            this.guardian.studentUuid = this.$route.params.uuid;
                            this.guardian.relationshipUuid = null;
                            this.guardian.firstName = null;
                            this.guardian.middleName = null;
                            this.guardian.lastName = null;
                            this.guardian.gender = null;
                            this.guardian.email = null;
                            this.guardian.phone = null;
                            this.guardian.occupation = null;
                            this.guardian.homeAddress = null;
							this.guardian.isCreate = true;
							this.guardian.loading = false;
							this.guardian.showModal = false;

							let message = response.data.message
							let type = "success"

							this.notify(type, message)

						}

						this.getGuardians()

					}).catch(error => {
					
						this.guardian.loading = false;
			
						if(error.response != null && error.response.status == 401) {
							this.$router.push(`/`);
						}

						let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
						let type = "danger"

						this.notify(type, message)
			
					});

				}else{

					this.guardian.loading = true;
		
					let url = `${this.$BACKEND_URL}/students/guardian/${this.guardian.uuid}`

					this.$AXIOS.patch(url, this.guardian).then(response => {

						if (response.status >= 200 && response.status < 210) {

							this.form.resetFields()

							this.guardian.uuid = null;
                            this.guardian.studentUuid = this.$route.params.uuid;
                            this.guardian.relationshipUuid = null;
                            this.guardian.firstName = null;
                            this.guardian.middleName = null;
                            this.guardian.lastName = null;
                            this.guardian.gender = null;
                            this.guardian.email = null;
                            this.guardian.phone = null;
                            this.guardian.occupation = null;
                            this.guardian.homeAddress = null;
							this.guardian.isCreate = true;
							this.guardian.loading = false;
							this.guardian.showModal = false;

							let message = response.data.message
							let type = "success"

							this.notify(type, message)

						}

						this.getGuardians()
		
					}).catch(error => {
					
						this.guardian.loading = false;
			
						if(error.response != null && error.response.status == 401) {
							this.$router.push(`/`);
						}

						let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
						let type = "danger"

						this.notify(type, message)
					});
				}
				
			},

            showGuardianDeletionConfirmation(uuid) {
				this.deleteDataGuardian.uuid = uuid;
				this.deleteDataGuardian.showModal = true;
			},

			cancelGuardianDeletion() {
				this.deleteDataGuardian.uuid = null;
				this.deleteDataGuardian.showModal = false;
			},

			onDeleteGuardian() {

				this.deleteDataGuardian.loading = true;

                let url = `${this.$BACKEND_URL}/students/guardian/${this.deleteDataGuardian.uuid}`;

                this.$AXIOS.delete(url).then((response) => {
                        
					this.deleteDataGuardian.uuid = null;
					this.deleteDataGuardian.showModal = false;
                
                    this.deleteDataGuardian.loading = false;

					let message = response.data.message;
                    let type = "success"

                    this.notify(type, message)

					this.getGuardians();

                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "danger"

                    this.notify(type, message)

                    this.deleteDataGuardian.loading = false;
                });
			},

			notify(type, message) {
				this.$notification.open({
					class: 'ant-notification-' + type,
					message: message,
					duration: 3.0,
					style: {fontSize: "10px !important"}
				});
			},

		},

		
	}

</script>

<style lang="scss">

	#txtBtn, #txtBtn:hover {
		border: none;
	}
    .ant-input[disabled], .ant-select-disabled {
        color: #414141;
        font-weight: 400;
    }
</style>